import { ErrorMessage } from '@hookform/error-message';

export default function ErrorMessageMultiple({ errors, name}) {
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => {
        if (message === undefined) {
          return null
        }

        if (typeof message === 'string') {
          return <div className="alert alert-danger">{message}</div>
        }

        if (Array.isArray(message)) {
          return Object.entries(message).map(([type, msg]) => (
            <div key={type} className="alert alert-danger">{msg}</div>
          ))

        }
      }
    }
  />

  )
}
