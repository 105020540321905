import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

import ErrorMessageMultiple from "./ErrorMessageMultiple.js";
import { API } from "../../services/Api.js";
import { file2base64 } from "../../services/file2base64.js";

export default function LegalPartnerForm() {
  const [sending, setSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    control,
    handleSubmit,
    register,
    reset,
    clearErrors,
    setError,
    setValue,
    formState: {
      errors,
      isDirty,
      isSubmitSuccessful,
      isValid,
    },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    defaultValues: {
      "type": "legal",
      // TESTING
      //"org_type": "P",
      //"org_scope": "L",
      //"denomination": "Candela",
      //"activity": "Surame",
      //"email": "test@candelacoop.com",
      //"confirmEmail": "test@candelacoop.com",
      //"address": "Calle Prueba",
      //"municipality": "Huelva",
      //"dni": "12312312K",
      //"phone": "666666666",
      //"agent_name": "Name",
      //"agent_dni": "12312312K",
      //"agent_phone": "666666666",
      //"agent_email": "agent@candelacoop.com",
      //"confirmAgentEmail": "agent@candelacoop.com",
      //"policy": true,
    },
  });

  const email = useWatch({control, name: "email"})
  const agent_email = useWatch({control, name: "agent_email"})

  const handleInputFile = (e) => {
    file2base64(e.target.files[0])
      .then(({mimetype, base64}) => {
        setValue("paymentFile", base64);
        setValue("paymentMimetype", mimetype);
      }).catch((err) => {
        setError("paymentFile", {type: "server", message: err})
      })
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setSending(true);
    setSuccessMessage("")
    clearErrors()

    API.members.register(data)
      .then((response) => {
        setSuccessMessage("¡Bienvenida a Candela!\nEn breve recibirás un correo electrónico confirmando tu alta.\nGracias por unirte al cambio energético")
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return error.response.json();
        }
        return {"": [error]}
      })
      .then((errJson) => {
        errJson && Object.entries(errJson).map(([field, msgs]) => (
          setError(field, {type: "server", message: msgs}, {shouldFocus: false})
        ))
      })
      .finally(() => setSending(false))
  }

  useEffect(() => {
    if (isSubmitSuccessful && isValid && successMessage) {
      reset({
        "org_type": "U",
        "org_scope": "U",
        "denomination": "",
        "activity": "",
        "email": "",
        "confirmEmail": "",
        "address": "",
        "municipality": "",
        "dni": "",
        "phone": "",
        "agent_name": "",
        "agent_dni": "",
        "agent_phone": "",
        "agent_email": "",
        "confirmAgentEmail": "",
        "known": "U",
        "social_council": "U",
        "policy": false,
      })
    }
    if (!isSubmitSuccessful && !isValid && isDirty) {
      setSuccessMessage("")
    }
  }, [isSubmitSuccessful, isValid, successMessage, isDirty])

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <h3>Datos organización (obligatorios)</h3>

      <input id="type" hidden {...register("type")} />

      <Row className="form-group">
        <Col sm={6}>
          <label htmlFor="org_type">Tipo de organización</label>
          <select id="org_type" {...register("org_type", {
            required: "Campo obligatorio",
            validate: {
              valid: val => ["P", "E", "O"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="P">Administración pública</option>
            <option value="E">ORG sindical/empresarial/política</option>
            <option value="O">Asociación/Funcdación/ONG</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="org_type" />
        </Col>
        <Col sm={6}>
          <label htmlFor="org_scope">Ámbito de actuación</label>
          <select id="org_scope" {...register("org_scope", {
            required: "Campo obligatorio",
            validate: {
              valid: val => ["L", "P", "A"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="L">Local</option>
            <option value="P">Provincial</option>
            <option value="A">Autonómico</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="org_scope" />
        </Col>
        <Col sm={6}>
          <label htmlFor="denomination">Denominación</label>
          <input id="denomination" type="text" {...register("denomination", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
})} />
          <ErrorMessageMultiple errors={errors} name="denomination" />
        </Col>
        <Col sm={6}>
          <label htmlFor="activity">Actividad que realiza</label>
          <input id="activity" type="text" {...register("activity", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
})} />
          <ErrorMessageMultiple errors={errors} name="activity" />
        </Col>
        <Col sm={6}>
          <label htmlFor="address">Domicilio social</label>
          <input id="address" type="text" autoComplete="address" {...register("address", { required: true, maxLength: 120})} />
          <ErrorMessageMultiple errors={errors} name="address" />
        </Col>
        <Col sm={6}>
          <label htmlFor="municipality">Municipio</label>
          <input id="municipality" type="text" autoComplete="city" {...register("municipality", { required: true, maxLength: 80})} />
          <ErrorMessageMultiple errors={errors} name="municipality" />
        </Col>
        <Col sm={6}>
          <label htmlFor="dni">NIF/CIF</label>
          <input id="dni" type="text" {...register("dni", {
            required: "Campo obligatorio",
            pattern: {
              value: /^[0-9]{8}[A-Za-z]{1}$/i,
              message: "Formato inválido",
            }
          })} />
          <ErrorMessageMultiple errors={errors} name="dni" />
        </Col>
        <Col sm={6}>
          <label htmlFor="phone">Teléfono</label>
          <input id="phone" type="phone" autoComplete="phone" {...register("phone", {
            required: "Campo obligatorio",
            maxLength: {
              value: 15,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="phone" />
        </Col>
        <Col sm={6}>
          <label htmlFor="email">Correo electrónico</label>
          <input
            id="email"
            type="email"
            autoComplete="email"
            {...register("email", {
              required: "Campo obligatorio",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Email no válido",
              },
            })} />
          <ErrorMessageMultiple errors={errors} name="email" />
        </Col>
        <Col sm={6}>
          <label htmlFor="confirmEmail">Confirmar correo electrónico</label>
          <input id="confirmEmail" type="email" autoComplete="email" {...register("confirmEmail", {
            required: "Campo obligatorio",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Email no válido",
            },
            validate: {
              confirm: confirmEmail => confirmEmail === email || "Email no son iguales",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="confirmEmail" />
        </Col>
        <Col sm={6}>
          <label htmlFor="birthdate">Comprobante de pago</label>
          <Form.Control id="paymentFile" size="lg" type="file" onChange={e => handleInputFile(e)} accept="application/pdf" required />
          <ErrorMessageMultiple errors={errors} name="paymentFile" />
        </Col>

      </Row>

      <h3>Datos de la persona representante (obligatorios)</h3>
      <Row className="form-group">
        <Col sm={12}>
          <label htmlFor="agent_name">Nombre</label>
          <input id="agent_name" type="text" autoComplete="name" {...register("agent_name", { required: true, maxLength: 80})} />
          <ErrorMessageMultiple errors={errors} name="agent_name" />
        </Col>
        <Col sm={6}>
          <label htmlFor="agent_dni">DNI/NIE</label>
          <input id="agent_dni" type="text" {...register("agent_dni", {
            required: "Campo obligatorio",
            validate: (value) => {
              const DNI_REGEX = /^(\d{8})([A-Z])$/;
              const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
              return DNI_REGEX.test(value) || NIE_REGEX.test(value) || "Formato inválido";
            }
          })} />
          <ErrorMessageMultiple errors={errors} name="agent_dni" />
        </Col>
        <Col sm={6}>
          <label htmlFor="agent_phone">Teléfono</label>
          <input id="agent_phone" type="phone" autoComplete="phone" {...register("agent_phone", {
            required: "Campo obligatorio",
            maxLength: {
              value: 15,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="agent_phone" />
        </Col>
        <Col sm={6}>
          <label htmlFor="agent_email">Correo electrónico</label>
          <input
            id="agent_email"
            type="email"
            autoComplete="email"
            {...register("agent_email", {
              required: "Campo obligatorio",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Email no válido",
              },
            })} />
          <ErrorMessageMultiple errors={errors} name="agent_email" />
        </Col>
        <Col sm={6}>
          <label htmlFor="confirmAgentEmail">Confirmar correo electrónico</label>
          <input id="confirmAgentEmail" type="email" autoComplete="email" {...register("confirmAgentEmail", {
            required: "Campo obligatorio",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Email no válido",
            },
            validate: {
              confirm: confirmAgentEmail => confirmAgentEmail === agent_email || "Email no son iguales",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="confirmAgentEmail" />
        </Col>
      </Row>

      <h3>Encuesta (opcional)</h3>
      <Row className="form-group">
        <Col sm={6}>
          <label htmlFor="known">¿Cómo nos conociste?</label>
          <select id="known" {...register("known", {
            validate: {
              valid: val => ["U", "R", "C", "P", "O"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="R">Por redes sociales (Facebook, Instagram, Twitter)</option>
            <option value="C">Por medio de comunicación (prensa, radio, etc)</option>
            <option value="P">A través de una persona ya socia</option>
            <option value="O">Otros</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="known" />
        </Col>

        <Col sm={6}>
          <label htmlFor="social_council">¿Queréis que vuestra entidad forme parte del consejo social?</label>
          <select id="social_council" {...register("social_council", {
            validate: {
              valid: val => ["U", "Y", "N"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="Y">Sí</option>
            <option value="N">No</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="social_council" />
        </Col>
      </Row>
      <Row>
        <label className="checkbox">
          <input type='checkbox' {...register("policy", {required: "Requerido"})} />
          <small>
            <p>
              Conozco y acepto la <Link target="_blank" to="/legal/politica-privacidad">política de privacidad</Link> y el <Link target="_blank" to="/legal/aviso-legal">tratamiento de datos</Link>.
            </p>
          </small>
        </label>
        <ErrorMessageMultiple errors={errors} name="policy" />
      </Row>

      <Row>
        <Col sm={4}>
          <button
            className="mb-4"
            type="submit"
          >
            { sending ? "Enviando ..." : "Enviar" }
          </button>
        </Col>
        <Col sm={8}>
          { Object.keys(errors) > 0 && (
            <div className="alert alert-danger">
              <span key="zero">Se han encontrado errores, por favor, corrige los errores en el formulario.</span>
            </div>
          )}
          { successMessage && <div className="alert alert-success">{successMessage}</div> }
        </Col>
      </Row>
    </Form>
  );
}
