import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { Container, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";

import ErrorMessageMultiple from "../components/ErrorMessageMultiple.js";
import { API, TOKEN_NAME } from "../../services/Api.js";
import Header from '../components/Header';
import Footer from '../components/Footer';

function Login() {
  document.title = 'Iniciar Sesión';
  const navigate = useNavigate();
  const [sending, setSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    handleSubmit,
    register,
    clearErrors,
    setError,
    formState: {
      errors,
      isSubmitSuccessful,
      isValid,
    },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    defaultValues: {
      // TESTING
      //"number": "123",
      //"dni": "12312312K",
    },
  });

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setSending(true);
    setSuccessMessage("")
    clearErrors()

    API.members.login(data)
      .then((response) => {
        localStorage.setItem(TOKEN_NAME, response.token);
        setSuccessMessage("Sesión iniciada correctamente")
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return error.response.json();
        }
        if (error.response.status === 401 || error.response.status === 403) {
          return {"": ["Error de autenticación"]}
        }
        return {"": ["Error interno"]}
      })
      .then((errJson) => {
        errJson && Object.entries(errJson).map(([field, msgs]) => (
          setError(field, { message: msgs }, { shouldFocus: false })
        ))
      })
      .finally(() => setSending(false))
  }

  useEffect(() => {
    if (isSubmitSuccessful && isValid && successMessage) {
      navigate("/")
    }
  }, [isSubmitSuccessful, isValid, successMessage, navigate])

  return (
    <div className="Home">
      <Header />

      <section className="about-ds">
        <Container className="section-header03">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <h2>Iniciar Sesión</h2>

            <Row className="login container">
              <Col>
                <label htmlFor="dni">DNI/NIE</label>
                <input id="dni" type="text" {...register("dni", {
                  required: "Campo obligatorio",
                  validate: (value) => {
                    const DNI_REGEX = /^(\d{8})([A-Z])$/;
                    const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
                    return DNI_REGEX.test(value) || NIE_REGEX.test(value) || "Formato inválido";
                  }
                })} />
                <ErrorMessageMultiple errors={errors} name="dni" />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <label htmlFor="number">Número de socia/o</label>
                <input id="number" type="text" {...register("number", {
                  required: "Campo obligatorio",
                  maxLength: {
                    value: 80,
                    message: "Campo demasiado largo",
                  },
                })} />
                <ErrorMessageMultiple errors={errors} name="number" />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <button className="mb-4" type="submit">
                  { sending ? "Iniciando sesión ..." : "Iniciar sesión" }
                </button>
              </Col>
              <Col lg={12} md={12} sm={12}>
                { Object.keys(errors).includes("") && (
                  <div className="alert alert-danger">
                    <span key="zero">{errors[""]?.message}</span>
                  </div>
                )}
                { successMessage && <div className="alert alert-success">{successMessage}</div> }
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
      <Footer />
    </div>
  )
}

export default Login;
