import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from "react-hook-form";
import { Col, Form, Row } from 'react-bootstrap';
import { Link } from "react-router-dom";

import ErrorMessageMultiple from "./ErrorMessageMultiple.js";
import { API } from "../../services/Api.js";
import { file2base64 } from "../../services/file2base64.js";

export default function NaturalPartnerForm() {
  const [sending, setSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const {
    control,
    handleSubmit,
    register,
    reset,
    setValue,
    clearErrors,
    setError,
    formState: {
      errors,
      isDirty,
      isSubmitSuccessful,
      isValid,
    },
  } = useForm({
    mode: "all",
    reValidateMode: "all",
    criteriaMode: "all",
    defaultValues: {
      "type": "natural",
      // TESTING
      //"name": "Name",
      //"surname": "Surame",
      //"email": "test@candelacoop.com",
      //"confirmEmail": "test@candelacoop.com",
      //"sex": "M",
      //"dni": "12312312K",
      //"birthdate": "1990-11-11",
      //"municipality": "Huelva",
      //"zipcode": "21007",
      //"address": "Calle Prueba",
      //"phone": "666666666",
      //"policy": true,
    },
  });

  const email = useWatch({control, name: "email"})

  const handleInputFile = (e) => {
    file2base64(e.target.files[0])
      .then(({mimetype, base64}) => {
        setValue("paymentFile", base64);
        setValue("paymentMimetype", mimetype);
      }).catch((err) => {
        setError("paymentFile", {type: "server", message: err})
      })
  }

  const onSubmit = async (data, e) => {
    e.preventDefault();
    setSending(true);
    setSuccessMessage("")
    clearErrors()

    API.members.register(data)
      .then((response) => {
        setSuccessMessage("¡Bienvenida a Candela!\nEn breve recibirás un correo electrónico confirmando tu alta.\nGracias por unirte al cambio energético")
      })
      .catch((error) => {
        if (error.response.status === 400) {
          return error.response.json();
        }
        return {"": [error]}
      })
      .then((errJson) => {
        errJson && Object.entries(errJson).map(([field, msgs]) => (
          setError(field, {type: "server", message: msgs}, {shouldFocus: false})
        ))
      })
      .finally(() => setSending(false))
  }

  useEffect(() => {
    if (isSubmitSuccessful && isValid && successMessage) {
      reset({
        "name": "",
        "surname": "",
        "email": "",
        "confirmEmail": "",
        "sex": "",
        "dni": "",
        "birthdate": "",
        "municipality": "",
        "zipcode": "",
        "address": "",
        "phone": "",
        "member": "U",
        "nationality": "",
        "job": "",
        "known": "U",
        "study": "U",
        "participate": "U",
        "policy": false,
      })
    }
    if (!isSubmitSuccessful && !isValid && isDirty) {
      setSuccessMessage("")
    }
  }, [isSubmitSuccessful, isValid, successMessage, isDirty])

  return (
    <Form id="natural-partner-form" onSubmit={handleSubmit(onSubmit)}>
      <h3>Datos personales (obligatorios)</h3>

      <input id="type" hidden {...register("type")} />

      <Row className="form-group">
        <Col sm={6}>
          <label htmlFor="name">Nombre</label>
          <input id="name" type="text" autoComplete="name" {...register("name", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="name" />
        </Col>
        <Col sm={6}>
          <label htmlFor="surname">Apellidos</label>
          <input id="surname" type="text" autoComplete="surname" {...register("surname", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="surname" />
        </Col>
        <Col sm={6}>
          <label htmlFor="email">Email</label>
          <input
            id="email"
            type="email"
            autoComplete="email"
            {...register("email", {
              required: "Campo obligatorio",
              pattern: {
                value: /^\S+@\S+$/i,
                message: "Email no válido",
              },
            })} />
          <ErrorMessageMultiple errors={errors} name="email" />
        </Col>
        <Col sm={6}>
          <label htmlFor="confirmEmail">Confirmar email</label>
          <input id="confirmEmail" type="email" autoComplete="email" {...register("confirmEmail", {
            required: "Campo obligatorio",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Email no válido",
            },
            validate: {
              confirm: confirmEmail => confirmEmail === email || "Email no son iguales",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="confirmEmail" />
        </Col>
        <Col sm={6}>
          <label htmlFor="sex">Sexo</label>
          <select id="sex" {...register("sex", {
            required: "Campo obligatorio",
            validate: {
              valid: val => ["M", "F"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="F">Mujer</option>
            <option value="M">Hombre</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="sex" />
        </Col>
        <Col sm={6}>
          <label htmlFor="dni">DNI/NIE</label>
          <input id="dni" type="text" {...register("dni", {
            required: "Campo obligatorio",
            validate: (value) => {
              const DNI_REGEX = /^(\d{8})([A-Z])$/;
              const NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
              return DNI_REGEX.test(value) || NIE_REGEX.test(value) || "Formato inválido";
            }
          })} />
          <ErrorMessageMultiple errors={errors} name="dni" />
        </Col>
        <Col sm={6}>
          <label htmlFor="birthdate">Fecha de nacimiento</label>
          <input id="birthdate" type="date" {...register("birthdate", {required: "Campo obligatorio"})} />
          <ErrorMessageMultiple errors={errors} name="birthdate" />
        </Col>
        <Col sm={6}>
          <label htmlFor="birthdate">Comprobante de pago</label>
          <Form.Control id="paymentFile" size="lg" type="file" onChange={e => handleInputFile(e)} accept="application/pdf" required />
          <ErrorMessageMultiple errors={errors} name="paymentFile" />
        </Col>
      </Row>

      <h3>Datos de contacto (obligatorios)</h3>
      <Row className="form-group">
        <Col sm={6}>
          <label htmlFor="address">Dirección</label>
          <input id="address" type="text" autoComplete="address" {...register("address", {
            required: "Campo obligatorio",
            maxLength: {
              value: 120,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="address" />
        </Col>
        <Col sm={6}>
          <label htmlFor="municipality">Municipio</label>
          <input id="municipality" type="text" autoComplete="city" {...register("municipality", {
            required: "Campo obligatorio",
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="municipality" />
        </Col>
        <Col sm={6}>
          <label htmlFor="zipcode">Código postal</label>
          <input id="zipcode" type="text" autoComplete="zipcode" {...register("zipcode", {
            required: "Campo obligatorio",
            pattern: {
              value: /^(0[1-9]|[1-4][0-9]|5[0-2])[0-9]{3}$/i,
              message: "Formato inválido",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="zipcode" />
        </Col>
        <Col sm={6}>
          <label htmlFor="phone">Teléfono</label>
          <input id="phone" type="phone" autoComplete="phone" {...register("phone", {
            required: "Campo obligatorio",
            maxLength: {
              value: 15,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="phone" />
        </Col>
      </Row>

      <h3>Encuesta (opcional)</h3>
      <Row className="form-group">
        <Col sm={6}>
          <label htmlFor="member">¿Eres socia de SOM Energía?</label>
          <select id="member" {...register("member", {
            validate: {
              valid: val => ["U", "Y", "N"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="Y">Sí</option>
            <option value="N">No</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="member" />
        </Col>
        <Col sm={6}>
          <label htmlFor="nationality">Nacionalidad</label>
          <input id="nationality" type="text" autoComplete="nationality" {...register("nationality", {
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="nationality" />
        </Col>
        <Col sm={6}>
          <label htmlFor="job">Profesión</label>
          <input id="job" type="text" autoComplete="city" {...register("job", {
            maxLength: {
              value: 80,
              message: "Campo demasiado largo",
            },
          })} />
          <ErrorMessageMultiple errors={errors} name="job" />
        </Col>
        <Col sm={6}>
          <label htmlFor="study">Nivel de estudios</label>
          <select id="study" {...register("study", {
            validate: {
              valid: val => ["U", "B", "M", "H"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="B">Básicos</option>
            <option value="M">Medios</option>
            <option value="H">Superiores</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="study" />
        </Col>
        <Col sm={6}>
          <label htmlFor="known">¿Cómo nos conociste?</label>
          <select id="known" {...register("known", {
            validate: {
              valid: val => ["U", "R", "C", "P", "O"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="R">Por redes sociales (Facebook, Instagram, Twitter)</option>
            <option value="C">Por medio de comunicación (prensa, radio, etc)</option>
            <option value="P">A través de una persona ya socia</option>
            <option value="O">Otros</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="known" />
        </Col>
        <Col sm={6}>
          <label htmlFor="participate">¿Deseas participar en un grupo de trabajo?, señala en cual</label>
          <select id="participate" {...register("participate", {
            validate: {
              valid: val => ["U", "T", "E", "C", "F"].includes(val) || "Campo obligatorio",
            },
          })}>
            <option value="U" default>--</option>
            <option value="T">Formación</option>
            <option value="E">Comunidades energéticas</option>
            <option value="C">Comunicación</option>
            <option value="F">Finanzas</option>
          </select>
          <ErrorMessageMultiple errors={errors} name="participate" />
        </Col>
      </Row>

      <Row>
        <label className="checkbox">
          <input type='checkbox' {...register("policy", {required: "Requerido"})} />
          <small>
            <p>
              Conozco y acepto la <Link target="_blank" to="/legal/politica-privacidad">política de privacidad</Link> y el <Link target="_blank" to="/legal/aviso-legal">tratamiento de datos</Link>.
            </p>
          </small>
        </label>
        <ErrorMessageMultiple errors={errors} name="policy" />
      </Row>

      <Row>
        <Col sm={4}>
          <button
            className="mb-4"
            type="submit"
          >
            { sending ? "Enviando ..." : "Enviar" }
          </button>
        </Col>
        <Col sm={8}>
          { Object.keys(errors).length > 0 && (
            <div className="alert alert-danger">
              <span key="zero">Se han encontrado errores, por favor, corrige los errores en el formulario.</span>
            </div>
          )}
          { successMessage && <div className="alert alert-success">{successMessage}</div> }
        </Col>
      </Row>

    </Form>
  );
}
