import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";


export default function ContactForm() {

  const { register, handleSubmit, formState } = useForm()

  const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false)
  const [isFailSubmitted, setIsFailSubmitted] = useState(false)

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const baseURL = process.env.REACT_APP_API_URL

    fetch(baseURL + 'contacts/', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((response) => (response.json()))
      .then((response) => {
        if (response.data.id) {
          setIsSuccessfullySubmitted(true)
          console.log("Submitting complete", response.data)
        } else {
          setIsFailSubmitted(true);
          console.info('Error server answer')
          console.info('Data:', data )
          console.info(response)
        }

    })
    .catch(error => {
      setIsFailSubmitted(true);
      console.error('Error:', error.message)
      console.error('Data:', data )
    })

    setTimeout(function(){
      document.getElementById("contact-form").reset()
    }, 5000);

  }

  return (
    <Col sm={12}>
      <div className="contact-two-bottom">
        <Row>
          <Col sm={12}>
            <h3>Escríbenos ...</h3>
            <form onSubmit={handleSubmit(onSubmit)} id="contact-form">
              <Row className="form-group">
                <Col sm={6}>
                  <input {...register("name", { required: true, maxLength: 80})}
                    type="text"
                    placeholder="Nombre *"
                  />
                  <span>{formState.errors.name && <div className="alert alert-danger">El nombre es obligatorio</div>}</span>
                </Col>
                <Col sm={6}>
                  <input {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
                    type="email"
                    placeholder="Email *"
                  />
                  <span>{formState.errors.email && <div className="alert alert-danger">El email es obligatorio</div>}</span>

                </Col>
                <Col sm={12}>
                  <textarea {...register("message", { required: true, maxLength: 5000 })} placeholder="Mensaje *">
                  </textarea>
                  <span>{formState.errors.message && <div className="alert alert-danger">El mensaje es obligatorio</div>}</span>

                </Col>
                <Col sm={12}>
                  <button type="submit"
                    disabled={formState.isSubmitting || isSuccessfullySubmitted}>Enviar</button>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <br/>
                  <div id="form-messages">
                    {formState.isSubmitting && (
                      <div className="alert alert-warning">Enviando...</div>
                    )}
                    {isSuccessfullySubmitted && (
                      <div className="alert alert-success">Enviado correctamente</div>
                    )}

                    {isFailSubmitted && (
                      <div className="alert alert-danger">Error al enviar</div>
                    )}
                  </div>
                </Col>
                <Col sm={12}>
                  <p>
                    <small>Con el envío de este formulario, acepto que CANDELA S. COOP.AND utilice los datos que he comunicado para dar curso a la solicitud de participación en la cooperativa y para lo que tenga que ver con la relación comercial o/y social que pudiera resultar (envío de documentación relacionada, contacto telefónico, e-mail, SMS, newsletter).</small>
                  </p>
                  <p>
                    <small>CANDELA S.COOP AND. le garantiza que protege sus datos personales. Para conocer y ejercer su derecho de acceso, rectificación o eliminación de los datos que ha proporcionado en este formulario, puede consultar nuestra <Link target="_blank" to="/legal/politica-privacidad">Política Privacidad</Link></small>
                  </p>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </div>
    </Col>

  );
}
